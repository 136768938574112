import React from "react";
import styles from "./Projects.module.scss";
import Loadable from "@loadable/component";

const LoadableGallery = Loadable(() =>
  import("../../components/Gallery/Gallery")
);

export default ({ pageTitle }) => {
  return (
    <div className={styles.root}>
      <div className="content-area">
        <div className="page-title-wrapper">
          <h1 className="page-title container-md">{pageTitle}</h1>
        </div>

        <div className="container-md">
          <div className={styles.lead}>
            <p>
              箕澤屋では、保存活動メンバーを中心に、現在もさまざまな活動を行っています。
              <br />
              活動内容の詳細はメールで不定期にお届けしています。ご興味ある方は、
              <a href="#newsletter" className="color-link">
                ニュースレター
              </a>
              にご登録いただけたらうれしいです。
            </p>
          </div>
        </div>

        <div className="container-xl">
          <LoadableGallery />
        </div>
      </div>
    </div>
  );
};
