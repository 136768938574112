import React from "react";
import Layout from "../../components/Layout";
import Projects from "../../components/Projects/Projects";

const ProjectsPage = ({ location }) => {
  const lang = "ja";

  // Page setting
  const pageTitle = "Projects";
  const pageSlug = "projects";
  const pageKeywords = [`プロジェクト`, `Projects`];
  const pageDescription = "箕澤屋で活動中のプロジェクトです";
  const pageImage = "";

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={pageTitle}
      pageSlug={pageSlug}
      pageKeywords={pageKeywords}
      pageDescription={pageDescription}
      pageImage={pageImage}
    >
      <Projects pageTitle={pageTitle} />
    </Layout>
  );
};

export default ProjectsPage;
